import { useNavigate } from "react-router-dom"

export default function Limite() {

    const navigate = useNavigate()
    return (
        <div className="bg">
            <div className="limite">
                <p className="limite-p1">
                Il semble que vous ayez atteint la limite de
                 10 questions par jour. Revenez demain pour poser
                  davantage de questions.
                </p>
                <button
                disabled
                onClick={''}
                className="limite-btn">Passer en mode illimité</button>
            </div>
        </div>
    )
}
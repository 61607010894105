import { initializeApp } from "firebase/app";
import 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCesPToEPuSBx2Zdv0CXbtLRkIyi28Y0VE",
  authDomain: "tchadiatech.firebaseapp.com",
  projectId: "tchadiatech",
  storageBucket: "tchadiatech.appspot.com",
  messagingSenderId: "1065057725712",
  appId: "1:1065057725712:web:63106908ed7ce6683e8995"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

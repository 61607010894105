import { useState } from 'react';
import './home.css';
import { AuthData } from '../../auth/Auth';
import myImg from '../../assets/images/myprofil.png'
import { ProgressBar } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useEffect } from 'react';
import logo from '../../assets/images/logo.png'


export default function Home() {

    const { user, handleSubmit, sendOTP, verifyOTP, registerData } = AuthData();

    //=======================================================
    const [send, setSend] = useState(true)
    const [phone, setPhone] = useState(true)
    const [otp, setOtp] = useState(false)
    const [userData, setUserData] = useState(false)
    const navigate = useNavigate()
    const [PhoneNumber, setPhoneNumber] = useState('')
    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    //=======================================================
    //=======================================================


    //=========================== Functions ============================

    const handlePopup = () => {
        setSend(false)
        handleSubmit().then(e => {
            if (e == true) {
                setSend(false)
                console.log(e);
            } else {
                setSend(false)
            }
        })
    }

    const handleSendPhone = () => {
        setSend(false)
        sendOTP(PhoneNumber).then((e) => {
            setSend(true)
            if (e.res == true) {
                setSend(true)
                setOtp(true)
            } else {
                setSend(true)
                setError('verifiez votre numero')
            }
        })
    }

    const handleSendCode = () => {
        setSend(false)
        verifyOTP(code).then(e => {
            if (e == false) {
                setSend(true)
                setError('Code incorrect')
            }
        })
    }

    //========================================
    useEffect(() => {
        setError('')
    }, [code, PhoneNumber])
    //========================================

    return (
        <div className="h">
            <div className="left">
                <div className='text'>
                    {/* <img className='logo' src={logo} /> */}
                    <p className='header-text'>
                        TCHADIA.TECH <span>Beta 2.1</span>
                    </p>
                    <p className='left-text1'>
                        Bonjour, je suis votre assistant virtuel.
                        Posez-moi des questions, discutons de vos besoins,
                        ou explorez le savoir à portée de main.
                        Je suis là pour vous aider !
                    </p>
                    <p className='citation'>" N'ayez pas peur du changement, car il est le chemin vers le progrès."</p>
                </div>
            </div>
            <div className='right'>
                <div className='form'>
                    {
                        send ?
                            <>
                                {!otp ?
                                    <>
                                        <p>Se connecter</p>
                                        <PhoneInput
                                            value={PhoneNumber}
                                            onChange={PhoneNumber => setPhoneNumber('+' + PhoneNumber)}
                                            country={'td'}
                                            inputClass='x'
                                            containerClass='y'

                                        />
                                        {send ?
                                            <>
                                                <div className='error'>{error}</div>
                                                <button onClick={handlePopup} className="btn-x">Se connecter</button>
                                            </>
                                            :
                                            <ProgressBar
                                                height="80"
                                                width="80"
                                                ariaLabel="progress-bar-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="progress-bar-wrapper"
                                                borderColor='#ffffff'
                                                barColor='#ffffff'
                                            />
                                        }

                                    </> :
                                    <>
                                        <p className='a'>Vous recevrez un code à six chiffres au {PhoneNumber}</p>
                                        <input className="x a" placeholder="code a six chiffres" value={code} onChange={e => setCode(e.target.value)} />
                                        {
                                            send ? <>
                                                <div className='error'>{error}</div>
                                                <button onClick={handleSendCode} className="btn-x">verification du code</button>
                                            </>
                                                :
                                                <ProgressBar
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="progress-bar-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="progress-bar-wrapper"
                                                    borderColor='#ffffff'
                                                    barColor='#ffffff'
                                                />
                                        }
                                    </>
                                }
                            </> :
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor='#ffffff'
                                barColor='#ffffff'
                            />
                    }
                    <div id='recaptcha'></div>


                    <button className='' onClick={handlePopup}>Se connecter avec Google</button>

                    <a href=''>Politique et Condition d'utilisation</a>
                </div>
            </div>

            <div className='h-footer'>
                <div className='w'>
                    <img className='myimg' src={myImg} />
                    <p>CEO - Founder - TCHADIA.Tech </p>
                    <p>Facebook : Ing Konayel - WhatsApp : +235 93875163</p>
                </div>
            </div>
            <div className='drapeau'>
                <div className='c bleu'></div>
                <div className='c jaune'></div>
                <div className='c rouge'></div>
            </div>
        </div>
    );
}
import { BrowserRouter } from 'react-router-dom';
import './app.css'
import AuthWrapper from './auth/Auth';


function App() {

  return (
    <BrowserRouter>
      <AuthWrapper />
    </BrowserRouter>
  );
}

export default App;

import { Route, Routes, Redirect, Navigate, defer, redirect, createBrowserRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import { AuthData } from "../auth/Auth";
import Limite from "../components/Limite";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../Config";



export default function PrivateRouter() {
  const { user } = AuthData();
  const db = getFirestore(app);

  return (
    <>
      {
        user ?
          <Routes>
            <Route
              path="/dashboard"
              element={<Dashboard />}
            />
            <Route
              path="/free-limite"
              element={<Limite />}
            />
          </Routes> : <Navigate to='/' />
      }
    </>
  );
}

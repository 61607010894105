import { createContext, useContext, useEffect, useState } from "react"
import Home from "../components/Home/Home"
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter, useNavigate } from "react-router-dom"
import PrivateRouter from "../Router/PrivateRoute"
import { app } from "../Config";
import { GoogleAuthProvider, RecaptchaVerifier, getAuth, onAuthStateChanged, signInWithPhoneNumber, signInWithPopup, signOut } from "firebase/auth";
import { arrayUnion, collection, doc, getDoc, getFirestore, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { ProgressBar, Circles } from "react-loader-spinner";


const AuthContext = createContext()
export const AuthData = () => useContext(AuthContext)

export default function AuthWrapper() {

    //================================= useState ==============================//
    const [user, setUser] = useState(null)
    const [data, setData] = useState(null)
    const [historik, setHistorik] = useState(null)
    const [logout, setLogout] = useState(false)
    const [confirmationResult, setConfirmationResult] = useState('')
    const [loading, setLoading] = useState(true)


    //================================= firebase gestion ==============================//

    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    const db = getFirestore(app);
    const usersRef = collection(db, "users");
    auth.languageCode = 'fr';

    //================================= Navigate ==============================//
    const navigate = useNavigate()


    //================================= Functions ==============================//
    const sendOTP = async (PhoneNumber) => {
        try {
            const recaptch = new RecaptchaVerifier(auth, 'recaptcha', {
                'size': 'invisible'
            })
            const confirmation = await signInWithPhoneNumber(auth, PhoneNumber, recaptch)
            setConfirmationResult(confirmation)
            return { res: true }
        } catch (error) {
            return { res: false, error: error }
        }
    }

    const verifyOTP = async (code) => {
        try {
            const result = await confirmationResult.confirm(code)
            const user = result.user
            const docRef = doc(db, "users", user.phoneNumber);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return setUser(user)
            } else {
                await setDoc(doc(usersRef, user.phoneNumber), {
                    name: user.phoneNumber,
                    email: user.phoneNumber,
                    numberDay: 0,
                    date: ''
                })
                return setUser(user)
            }
        } catch (error) {
            return false
        }

    }

    const handleSave = async ({ name, email, number, user }) => {
        const docRef = doc(db, "users", email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUser(user)
        } else {
            await setDoc(doc(usersRef, email), {
                name: name,
                email: email,
                numberDay: number,
                date: '',
                reqRes: {
                    req: [],
                    res: [],
                }
                
            })
            setUser(user)
        }
    }

    const addReqRes = async ({ req: req, res: res, email: email }) => {
        try {
            const docRef = doc(db, "users", email);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                updateDoc(docRef, {
                    "reqRes.req": arrayUnion(req),
                    "reqRes.res": arrayUnion(res)
                })
            }
            return true
        } catch (error) {
            return false
        }
    }


    const handleSubmit = async () => {
        try {

            const result = await signInWithPopup(auth, provider)
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            const res = await handleSave({ name: user.displayName, email: user.email, number: 0, date: '', user })
            return res
        } catch (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            return error
            // ...
        }
    }


    const handleLogout = () => {
        signOut(auth).then(() => {
            setLogout(true)
        }).catch((error) => {
            // An error happened.
        });
    }

    const fetchData =async (authUser)=>{
        setLoading(true)
        try{
            const docRef = doc(db, "users", authUser.email);
            const docSnap = await getDoc(docRef);
            setData(docSnap.data())
            setHistorik(docSnap.data().reqRes.req)
            setLoading(false)
            setUser(authUser);
            return true

        }catch (error){
            return error
        }
    }

    //================================= useEffect ==============================//

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                // L'utilisateur est connecté
                fetchData(authUser).then(e=>{
                    if(e){
                        navigate('/dashboard')
                    }else{
                        navigate('/')
                    }
                })
              
            } else {
                // Aucun utilisateur n'est connecté
                setUser(null);
                setLoading(false)
                navigate('/')
            }
        });

        // Il est important de se désabonner lorsque le composant est démonté
        return () => {
            unsubscribe();
        };

    }, [logout, user])






    return (
        <AuthContext.Provider value={{ user, handleSubmit, handleLogout, sendOTP, verifyOTP, addReqRes , dataUser: data , historik }}>
            <Routes>
                <Route path="/" element={loading ? <LoadingPage /> : <Home />} />
            </Routes>
            <PrivateRouter />
        </AuthContext.Provider>
    )
}


export const LoadingPage = () => {
    return (
        <div className="loadingPage">
            <Circles
                height="80"
                width="80"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                color="#ffffff"
            />
        </div>
    )
}
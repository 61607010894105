import { useEffect, useRef, useState } from 'react';
import '../assets/css/dashboard.css'
import { List, LogOut, Plus } from 'react-feather';
import OpenAI from "openai";
import { ColorRing, Comment } from 'react-loader-spinner';
import { AuthData } from '../auth/Auth';
import { signOut } from 'firebase/auth';
import { FieldValue, doc, getDoc, getFirestore, increment, onSnapshot, updateDoc } from 'firebase/firestore';
import { app } from '../Config';
import { useLoaderData, useNavigate } from 'react-router-dom';



function Dashboard() {


  //==================================== useState=======================
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newRes, setNewRes] = useState(false);
  const messageRef = useRef(null);
  const [date, setDate] = useState(null)
  const [questions, setQuestions] = useState([])
  const db = getFirestore(app);
  const [req, setReq] = useState('')
  const [send, setSend] = useState(false)
  const { user, handleLogout, addReqRes , dataUser, historik} = AuthData();
  const navigate = useNavigate();


  //==================================== Gestion API =======================
  const openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true
  });
  const apiUrl = 'https://worldtimeapi.org/api/ip'; // Cela renvoie l'heure basée sur l'adresse IP du client

  //=========================================== Functions =======================
  const fetchDataFunvtion = async () => {
    setSend(true)
    const createur = req.toLocaleLowerCase() 
    try {
      if (req.length != 0) {
        if(createur.includes('konayel')){
          const resCreateur = "Konayel Maxime est le créateur de l'application web Tchadia.tech (une application d'assistance basée sur l'API OpenAI et développée par Konayel.)"
          setReq("");
          setNewRes(true)
          setLoading(false);
          setResponses(prevResponses => [...prevResponses, resCreateur]);
          addReqRes({req:req, res:resCreateur, email:user.email})
          .then(e=>{
            if(e){
              console.log('added');
            }
            else{
              console.log('error add');
            }
          })
          setSend(false)
        }else{
          const completion = await openai.chat.completions.create({
            messages: [{ role: "system", content: req }],
            model: "gpt-3.5-turbo-16k-0613",
          });
          setReq("");
          setNewRes(true)
          setLoading(false);
          setSend(false)
          const response = completion.choices[0].message.content
          if(response.toLocaleLowerCase().includes('openai')){
            const personalizedResponse = response.toLocaleLowerCase().replace('openai', "TchadIA (une application web d'assistance créée par Konayel basée sur l'API OpenAI)")
            console.log(personalizedResponse);
            setResponses(prevResponses => [...prevResponses, personalizedResponse]);
            addReqRes({req:req, res:personalizedResponse, email:user.email})
            .then(e=>{
              if(e){
                console.log('personalized');
              }
              else{
                console.log('error add');
              }
            })
            setSend(false)
          }else{
            setResponses(prevResponses => [...prevResponses, response]);
            addReqRes({req:req, res:response, email:user.email})
            .then(e=>{
              if(e){
                console.log('added');
              }
              else{
                console.log('error add');
              }
            })
            setSend(false)
          }

      
          setSend(false)
        }


      } else {

      }

    } catch (error) {
      setNewRes(true)
      console.log(error);
      setResponses(prevResponses => [...prevResponses, 'Verifiez votre connection !']);
      setLoading(false);
      setSend(false)
    }
  }
  const fetchData = async () => {
    const docRef = doc(db, "users", user.email ? user.email : user.phoneNumber);
    setQuestions(prevQuestion => [...prevQuestion, req])
    setLoading(true)

    if (dataUser.date == date) {
      if (dataUser.numberDay <= 10) {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          updateDoc(docRef, {
            numberDay: increment(1)
          })
        }
        fetchDataFunvtion();
      } else {
        setLoading(false)
        navigate('/free-limite')

      }
    } else {
      const docRef = doc(db, "users", user.email ? user.email : user.phoneNumber);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        updateDoc(docRef, {
          date: date,
          numberDay: 0
        })
      }
      fetchDataFunvtion()
      setLoading(false)

    }
  };

  const scrollToBottom = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //========================================= useEffect ============================
  useEffect(() => {

    if (responses.length > 0 && newRes) {
      const div = document.getElementById('response-list');
      const reponse = responses[responses.length - 1];
      const question = questions[questions.length - 1];
      const divReq = document.createElement('div')
      const divRes = document.createElement('div');
      const pReponse = document.createElement('pre');
      const pQuestion = document.createElement('p');
      divReq.className = 'question';
      pQuestion.innerText = question;
      divRes.className = 'reponse';
      divReq.appendChild(pQuestion);
      divRes.appendChild(pReponse);
      div.appendChild(divReq);
      div.appendChild(divRes);

      let currentIndex = 0;
      const interval = 20; // Délai en millisecondes entre chaque lettre

      const timer = setInterval(() => {
        if (currentIndex < reponse.length) {
          const letter = document.createTextNode(reponse.charAt(currentIndex));
          pReponse.appendChild(letter);
          currentIndex++;
          scrollToBottom()
        } else {
          clearInterval(timer);
        }
      }, interval);
    }
    setNewRes(false)
  }, [responses, req])

  const dataRealtime = async () => {
    const docRef = doc(db, "users", user.email ? user.email : user.phoneNumber);
    const docSnap = await getDoc(docRef);
    onSnapshot(docRef, (doc) => {
      //setDataUser(docSnap.data())
    });
  }

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setDate(data.datetime.substring(0, 10))
      })
      .catch((error) => {
        return error
      });
    //dataRealtime()
    scrollToBottom()

  }, [loading])


  return (
    <div className="dashboard">
      <div className="nav">
        <div className='small space'>
          <p>{user.displayName}</p>
          <LogOut className='btn' onClick={handleLogout} />
        </div>
        <div className='large flex'>
          <div className='new-chat large'>
            <div className='name'>{user.displayName ? user.displayName : user.phoneNumber}</div>
          </div>

        </div>
        <div className='footer large'>
          <div className=' new-chat btn' onClick={handleLogout}>
            <LogOut /> Deconnexion
          </div>
        </div>
      </div>
      <div className="res"  >
        {
            historik.map((result, index)=>{
             return (
             <div key={index}>
               <div className='question'>
                 <p>{result}</p>
               </div>
               <div className='reponse'>
                 <pre>{dataUser.reqRes.res[index]}</pre>
               </div>
             </div>)
          })
        }
        <div key={''} className='list' id='response-list'>
          {/* add responses */}
          
        </div>
        <div className='loading'>
          {loading ??
            <p className='question'>
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#ffffff', '#ffffff', '#f8b26a', '#ffffff', '#ffffff']}
              />
            </p>}</div>
        <div className='vide1'></div>
        <div ref={messageRef} />
        <div className='vide'></div>
        <div className='footer'>
          <div className='search'>
            <input placeholder='Send a message' value={req} onChange={e => setReq(e.target.value)} />

            {
              send ?
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#ffffff', '#ffffff', '#f8b26a', '#ffffff', '#ffffff']}
                /> :
                <button className='send' onClick={fetchData} >send </button>
            }
          </div>
          <p>Tchad 2023 Ingenieur Konayel</p>
        </div>
        
      </div >
    </div>
  );
}

export default Dashboard;
